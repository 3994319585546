.toast-main {
  color: #5ca595;
}

.toast-prog {
  background: linear-gradient(to right, #5ca595, #deeafb);
}

.toast-delete-main {
  color: red;
}

.toast-delete-prog {
  background: linear-gradient(to right, #5ca595, red);
}

html,
body {
  height: 100%;
}

#root {
  height: 100%;
  background: #f5f6f7;
}

.recharts-surface {
  overflow: visible;
}

input[type='number'] {
  -moz-appearance: textfield;
}
